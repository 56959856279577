define('checkin/models/quote-line', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    quote: belongsTo('quote'),
    core: belongsTo('core'),

    hollanderNum: attr('string'),
    customerQuantity: attr('number'),
    carPartPrice: attr('number')
  });
});