define('checkin/models/shelf-report', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    coreId: attr('string'),
    size: attr('string'),
    shelfNums: attr('list', {
      defaultValue: function defaultValue() {
        return [];
      }
    })
  });
});