define('checkin/models/profile', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    name: attr('string'),
    user: belongsTo('user'),

    carts: hasMany('cart'),
    activeCart: belongsTo('cart'),

    createdAt: attr('date')
  });
});