define('checkin/services/clock', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),

    clock: null,

    fetch: function fetch() {
      var _this = this;

      return Ember.get(this, 'store').findRecord('clock', Ember.get(this, 'session.data.authenticated.user_id')).then(function (clock) {
        Ember.set(_this, 'clock', clock);
        return clock;
      });
    },
    start: function start() {
      Ember.set(this, 'clock.started', true);
      Ember.set(this, 'clock.ended', false);

      return Ember.get(this, 'clock').save();
    },
    end: function end() {
      Ember.set(this, 'clock.ended', true);
      Ember.set(this, 'clock.started', false);

      return Ember.get(this, 'clock').save();
    },


    started: Ember.computed.alias('clock.started')
  });
});