define('checkin/components/order-peek/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    order: null
  }).reopenClass({
    positionalParams: ['order']
  });
});