define('checkin/helpers/new-record', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Helper = Ember.Helper,
      inject = Ember.inject,
      get = Ember.get;
  exports.default = Helper.extend({
    store: inject.service(),

    compute: function compute(params) {
      return get(this, 'store').createRecord(params[0], params[1] || {});
    }
  });
});