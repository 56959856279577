define('checkin/data/shelf-nums', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var shelfNums = [];

  shelfNums.push('AFR');

  var _loop = function _loop(i) {
    shelfNums = shelfNums.concat(['A', 'B', 'C', 'D', 'E', 'F', 'Gr'].map(function (l) {
      return [i, l].join('');
    }));
  };

  for (var i = 1; i <= 90; i++) {
    _loop(i);
  }

  for (var i = 1; i <= 25; i++) {
    shelfNums.push(['PAL', i].join(''));
  }

  for (var _i = 1; _i <= 122; _i++) {
    shelfNums.push(['BAR', _i].join(''));
  }

  for (var _i2 = 1; _i2 <= 130; _i2++) {
    shelfNums.push(['RP', _i2].join(''));
  }

  for (var _i3 = 1; _i3 <= 10; _i3++) {
    shelfNums.push(['GB', _i3].join(''));
  }

  var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  for (var _i4 = 0; _i4 < chars.length; _i4++) {
    for (var j = 0; j < chars.length; j++) {
      shelfNums.push([chars[_i4], chars[j]].join(''));
    }
  }

  exports.default = shelfNums;
});