define('checkin/components/core-search/bar/component', ['exports', 'ember-keyboard'], function (exports, _emberKeyboard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      on = Ember.on;
  exports.default = Component.extend(_emberKeyboard.EKMixin, {
    classNames: ['search-bar'],

    keyboardActivated: false,
    keywords: null,

    onSlash: on((0, _emberKeyboard.keyDown)('Slash'), function (event) {
      event.preventDefault(); // TODO
      this.$('input').focus().select();
    }),

    didInsertElement: function didInsertElement() {
      this.$('input').focus();
    },


    actions: {
      clear: function clear() {
        this.$('input').val('');
      },
      focus: function focus() {
        this.$('input').focus();
      },
      blur: function blur() {
        this.$('input').blur();
      },
      submit: function submit(e) {
        e.preventDefault();
      }
    }
  });
});