define('checkin/transforms/moment', ['exports', 'ember-data', 'dayjs'], function (exports, _emberData, _dayjs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Transform = _emberData.default.Transform;
  exports.default = Transform.extend({
    serialize: function serialize(deserialized) {
      if (deserialized) {
        return _dayjs.default.utc(deserialized).format('YYYY-MM-DD[T]HH:mm:ss[Z]');
      } else {
        return null;
      }
    },

    deserialize: function deserialize(serialized) {
      if (serialized) {
        return _dayjs.default.utc(serialized);
      } else {
        return null;
      }
    }
  });
});