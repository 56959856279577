define('checkin/routes/search', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route,
      RSVP = Ember.RSVP,
      get = Ember.get,
      set = Ember.set;
  var PromiseObject = _emberData.default.PromiseObject;
  exports.default = Route.extend({
    model: function model() {
      var store = this.store;

      return RSVP.hash({
        batches: store.query('batch', { include: 'customer', page: { number: 1, size: 100 } }).then(function () {
          return store.peekAll('batch').filterBy('isNew', false, { live: true });
        }),
        customers: store.query('customer', { page: { number: 1, size: 500 }, sort: 'name' }).then(function () {
          return store.peekAll('customer').filterBy('isNew', false, { live: true });
        }),
        categories: store.query('category', { page: { number: 1, size: 50 }, include: 'variants' })
      });
    }
  });
});