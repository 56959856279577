define('checkin/models/clock', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    startedAt: attr('date', { readOnly: true }),
    endedAt: attr('date', { readOnly: true }),

    started: attr('boolean', { writeOnly: true }),
    ended: attr('boolean', { writeOnly: true })
  });
});