define('checkin/components/allocation-button/component', ['exports', 'ember-changeset-validations', 'checkin/validations/allocation', 'ember-changeset'], function (exports, _emberChangesetValidations, _allocation, _emberChangeset) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Component = Ember.Component;
	exports.default = Component.extend({
		showEditor: false,

		didInsertElement: function didInsertElement() {
			this.set('changeset', new _emberChangeset.default(this.get('core.allocation.content'), (0, _emberChangesetValidations.default)(_allocation.default), _allocation.default));
		},
		click: function click() {
			this.set('showEditor', !this.get('showEditor'));
		}
	});
});