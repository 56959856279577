define('checkin/helpers/list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Helper = Ember.Helper;
  exports.default = Helper.helper(function (params) {
    return params;
  });
});