define('checkin/models/cart', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    profile: belongsTo('profile', { polymorphic: true, inverse: 'carts' }),

    lines: hasMany('cartLine'),

    closed: attr('boolean'),
    closedAt: attr('date'),

    code: attr('string'),
    name: attr('string'),

    subtotal: attr('number'),
    gst: attr('number'),
    total: attr('number'),

    exchangeRate: attr('number'),
    exchangedTotal: attr('number'),
    exchangedSubtotal: attr('number'),
    exchangedGst: attr('number'),

    showsExchange: attr('number'),
    includesGst: attr('boolean'),

    empty: attr('boolean', { readOnly: true }),

    locked: attr('boolean')
  });
});