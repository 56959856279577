define('checkin/models/collector', ['exports', 'checkin/models/profile', 'ember-data'], function (exports, _profile, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;
  exports.default = _profile.default.extend({
    discountRate: attr('number'),
    tracked: attr('boolean'),

    lastTokenStamp: attr('date'),

    chattable: attr('boolean'),
    discountable: attr('boolean'),

    rateLimited: attr('boolean'),

    searchesCount: attr('number', { defaultValue: 0 }),
    priceSearchesCount: attr('number', { defaultValue: 0 }),

    email: attr('string'),
    idType: attr('string'),
    idNumber: attr('string'),

    disabledAt: attr('date'),

    paymentMethod: attr('string'),

    ips: attr('stamp-map'),

    createdAt: attr('date')
  });
});