define('checkin/routes/authentication', ['exports', 'cac-common/routes/authentication'], function (exports, _authentication) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _authentication.default;
    }
  });
});