define('checkin/components/core-print-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['core-print-button'],

    clock: Ember.inject.service(),

    open: false,
    count: 1
  });
});