define("checkin/utils/resolve-as", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = resolveAs;
  function resolveAs(promise, as) {
    return promise.then(function () {
      return as;
    });
  }
});