define('checkin/controllers/authenticated', ['exports', 'ember-pusher'], function (exports, _emberPusher) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      service = Ember.inject.service,
      get = Ember.get;
  exports.default = Controller.extend(_emberPusher.default.Bindings, {
    PUSHER_SUBSCRIPTIONS: {
      'private-collections-empty-shelves': ['changed']
    },

    session: service(),

    actions: {
      changed: function changed(_ref) {
        var name = _ref.name;

        if (name === 'empty-shelves') {
          this.send('fetchShelves', get(this, 'model'));
        }
      }
    }
  });
});