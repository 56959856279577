define('checkin/routes/authenticated', ['exports', 'checkin/config/environment', 'cac-common/mixins/authenticated-route-mixin'], function (exports, _environment, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route,
      service = Ember.inject.service,
      get = Ember.get,
      set = Ember.set,
      $ = Ember.$;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    session: service(),
    notify: service(),

    model: function model() {
      return {};
    },
    afterModel: function afterModel() {
      this.setUpAndAuthorizePusher();
    },
    setUpAndAuthorizePusher: function setUpAndAuthorizePusher() {
      var headers = {};
      var data = get(this, 'session.data.authenticated');
      var authorizer = get(this, 'authorizer');

      authorizer.authorize(data, function (key, value) {
        headers[key] = value;
      });

      get(this, 'pusher').setup(_environment.default.APP.PUSHER_KEY, {
        cluster: _environment.default.APP.PUSHER_CLUSTER,
        encrypted: true,
        authEndpoint: _environment.default.APP.API_HOST + '/pusher/auth',
        auth: { headers: headers }
      });
    },


    actions: {
      fetchShelves: function fetchShelves(model) {
        // 
      },
      signOut: function signOut() {
        get(this, 'session').invalidate();
        this.transitionTo('authentication');
      },
      editCheckinCount: function editCheckinCount(id) {
        var _this = this;

        var checkin = this.store.peekRecord('checkin', id);
        var count = window.prompt("New count?");

        if (count && $.isNumeric(count) && count > 0) {
          set(checkin, 'count', count);

          return checkin.save().then(function () {
            return get(checkin, 'affectedStockpile');
          }).then(function (stockpile) {
            return stockpile ? stockpile.reload() : null;
          }).then(function () {
            return get(checkin, 'core');
          }).then(function (core) {
            return get(core, 'allocation');
          }).finally(function () {
            get(_this, 'notify').success("Checkin count saved");
          });
        } else {
          window.alert("Invalid count");
        }
      },
      destroyCheckin: function destroyCheckin(id) {
        var _this2 = this;

        var checkin = this.store.peekRecord('checkin', id);
        var stockpile = get(checkin, 'affectedStockpile');

        return checkin.destroyRecord().then(function () {
          return stockpile;
        }).then(function (stockpile) {
          return stockpile.reload();
        }).then(function () {
          return get(checkin, 'core');
        }).then(function (core) {
          return get(core, 'allocation');
        }).finally(function () {
          get(_this2, 'notify').success("Checkin deleted");
        }).catch(function (reason) {
          if (reason.errors) {
            get(_this2, 'notify').error(get(reason.errors, 'firstObject.detail'));
          }

          throw reason;
        });
      }
    }
  });
});