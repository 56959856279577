define('checkin/helpers/snapshot', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Helper = Ember.Helper,
      get = Ember.get,
      getProperties = Ember.getProperties,
      setProperties = Ember.setProperties;
  exports.default = Helper.helper(function (params) {
    var changeset = params[0];
    var model = params[1];

    setProperties(changeset, getProperties(model, get(model, 'keys')));

    return changeset.snapshot();
  });
});