define('checkin/helpers/clamp', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _objectDestructuringEmpty(obj) {
    if (obj == null) throw new TypeError("Cannot destructure undefined");
  }

  var Helper = Ember.Helper;


  var isSimilar = function isSimilar(a, b) {
    var aMatch = a.match(/(\d+)+(?!.*\d)$/);
    var bMatch = b.match(/(\d+)+(?!.*\d)$/);

    return aMatch && bMatch && +aMatch[1] - +bMatch[1] === 1;
  };

  var normalize = function normalize(string) {
    return string.replace(/[0-9]{1,3}/, function (n) {
      return n.toString().padStart(3, '0');
    });
  };

  exports.default = Helper.helper(function (_ref, _ref2) {
    var _ref3 = _slicedToArray(_ref, 1),
        items = _ref3[0];

    _objectDestructuringEmpty(_ref2);

    return (items || []).sort(function (a, b) {
      return normalize(a).localeCompare(normalize(b));
    }).reduce(function (memo, item, index, items) {
      if (index === 0 || !isSimilar(item, items[index - 1])) {
        memo.push([item]);
      } else {
        memo[memo.length - 1].push(item);
      }

      return memo;
    }, []).map(function (items) {
      if (items.length === 1) {
        return items[0];
      } else {
        return items[0] + '-' + items[items.length - 1].match(/(\d+)+(?!.*\d)$/)[1];
      }
    });
  });
});