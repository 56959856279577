define('checkin/components/select-or-action-dropdown/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      run = Ember.run,
      get = Ember.get,
      set = Ember.set;
  exports.default = Component.extend({
    classNames: 'ui floating dropdown labeled icon button'.w(),
    tabindex: 0,
    attributeBindings: ['tabindex'],

    options: null,
    selectedOption: null,
    prevSelectedOption: null,
    placeholder: null,

    didInsertElement: function didInsertElement() {
      var _this = this;

      this.$().dropdown({
        allowTab: false,
        showOnFocus: true,
        fullTextSearch: true,
        selectOnKeydown: false,

        onShow: function onShow() {
          _this.$().data('moduleDropdown').event.text.focus();

          run(function () {
            set(_this, 'tabindex', null);
          });
        },

        onHide: function onHide() {
          _this.$().data('moduleDropdown').event.mouseup();

          run(function () {
            set(_this, 'tabindex', 0);
          });
        },

        onChange: function onChange(value) {
          run(function () {
            var option = get(_this, 'options').findBy('id', value);
            _this.select(option);
          });
        }
      });

      this.$searchInput = this.$('.ui.search.icon.input > input');
      this.$actionButton = this.$('.ui.fluid.button');

      this.hijackActionButtonClick();
    },
    didRender: function didRender() {
      var _this2 = this;

      run(function () {
        _this2.$().dropdown('set selected', get(_this2, 'selectedOption.id'));
      });
    },
    didUpdate: function didUpdate() {
      // NOTE: conflict bug with `.active` and `classNameBindings`
      if (get(this, 'selectedOption')) {
        this.$().addClass(get(this, 'activeClass'));
      } else {
        this.$().removeClass(get(this, 'activeClass'));
      }
    },
    keyDown: function keyDown(e) {
      if (e.keyCode === 13 || e.keyCode === 32) {
        if (this.$actionButton.is(':focus')) {
          set(this, 'showCreate', true);
        }
      }
    },
    focusOut: function focusOut(event) {
      // if (this.$(event.target).is(this.$searchInput) && !this.$(event.relatedTarget).is(this.$actionButton)) {
      //   this.$().dropdown('hide');
      // }

      if (this.$(event.target).is(this.$actionButton) && !this.$(event.relatedTarget).is(this.$searchInput)) {
        var module = this.$().data('moduleDropdown');

        module.event.mouseup();
        module.event.blur();
      }
    },
    hijackActionButtonClick: function hijackActionButtonClick() {
      var module = this.$().data('moduleDropdown');
      var oldEventItemClick = module.event.item.click;

      this.$actionButton.on('focus', function () {
        module.event.item.click = function () {};
      }).on('blur', function () {
        module.event.item.click = oldEventItemClick;
      });
    },
    click: function click() {
      if (!get(this, 'options.length')) {
        set(this, 'showCreate', true);
      }
    },


    actions: {
      deselect: function deselect() {
        set(this, 'selectedOption', null);
        this.$().dropdown('remove selected').dropdown('hide');
      }
    }
  });
});