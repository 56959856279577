define('checkin/controllers/search/index', ['exports', 'ember-pusher', 'checkin/utils/store-locally', 'checkin/utils/find-and-set-by'], function (exports, _emberPusher, _storeLocally, _findAndSetBy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      get = Ember.get,
      service = Ember.inject.service;
  exports.default = Controller.extend(_emberPusher.default.Bindings, {
    notify: service(),

    PUSHER_SUBSCRIPTIONS: {
      'private-resources-allocations': ['updated'],
      'private-resources-batches': ['created'],
      'private-resources-customers': ['created'],
      'private-resources-labels': ['updated']
    },

    queryParams: ['keywords', {
      categoryName: 'category'
    }],

    keywords: null,
    categoryName: null,

    batchId: (0, _storeLocally.default)('batch'),
    batch: (0, _findAndSetBy.default)('model.batches', 'id', 'batchId'),

    printerIdx: (0, _storeLocally.default)('printerIdx', 0),

    isLoading: false,

    actions: {
      created: function created(_ref) {
        var _this = this;

        var type = _ref.type,
            id = _ref.id;

        this.store.find(type, id).then(function (record) {
          if (type === 'batch') {
            get(_this, 'notify').info('Batch just created for customer ' + get(record, 'customer.name'));
          }
        });
      },
      updated: function updated(_ref2) {
        var _this2 = this;

        var type = _ref2.type,
            id = _ref2.id;

        this.store.findRecord(type, id, { reload: true }).then(function (record) {
          if (type === 'label') {
            var method = get(record, 'printerJobEnded') ? 'info' : 'alert';

            get(record, 'core').then(function (core) {
              get(_this2, 'notify')[method]('Ended printing labels for core ' + get(core, 'cacNum'));
            });
          }
        });
      }
    }
  });
});