define('checkin/helpers/max', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Helper = Ember.Helper;
  exports.default = Helper.helper(function (params) {
    return Math.max.apply(null, params);
  });
});