define('checkin/transforms/markdown', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Transform = _emberData.default.Transform;
  var _window = window,
      marked = _window.marked;
  exports.default = Transform.extend({
    serialize: function serialize(deserialized) {
      return deserialized.raw;
    },

    deserialize: function deserialize(serialized, attributeMeta) {
      var options = attributeMeta.options && attributeMeta.options.markdown || {};

      return marked(serialized, options);
    }
  });
});