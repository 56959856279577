define('checkin/transforms/list', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Transform = _emberData.default.Transform;
  var isArray = Ember.isArray;
  exports.default = Transform.extend({
    serialize: function serialize(deserialized) {
      if (isArray(deserialized)) {
        return deserialized;
      } else {
        throw new Error("Did not receive deserialized array");
      }
    },

    deserialize: function deserialize(serialized) {
      if (isArray(serialized)) {
        return serialized;
      } else {
        throw new Error("Did not receive serialized array");
      }
    }
  });
});