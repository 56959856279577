define('checkin/components/core-search/search/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      equal = Ember.computed.equal;
  exports.default = Component.extend({
    isLoading: null,

    keywords: null,
    categoryName: null,

    classNames: ['core-search'],
    keywordsIsNull: equal('keywords', null)
  });
});