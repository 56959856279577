define('checkin/components/promising-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set,
      computed = Ember.computed;
  exports.default = Component.extend({
    tagName: 'button',
    attributeBindings: ['isDisabled:disabled', 'tabindex'],
    classNameBindings: ['fulfilling'],

    fulfilling: computed('isFulfilling', 'fulfillingClass', function () {
      if (get(this, 'isFulfilling')) {
        return get(this, 'fulfillingClass');
      }
    }),

    action: null,
    isFulfilling: false,

    isDisabled: computed('isEnabled', 'isFulfilling', function () {
      return !get(this, 'isEnabled') || get(this, 'isFulfilling');
    }),
    isEnabled: true,

    click: function click() {
      var _this = this;

      set(this, 'isFulfilling', true);

      var promise = this.action();

      if (promise) {
        promise.finally(function () {
          if (!get(_this, 'isDestroyed')) {
            set(_this, 'isFulfilling', false);
          }
        });
      } else {
        set(this, 'isFulfilling', false);
      }
    }
  });
});