define('checkin/models/batch', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    code: attr('string'),

    customer: belongsTo('customer'),
    checkins: hasMany('checkin'),
    receipt: belongsTo('receipt'),

    createdAt: attr('date', { readOnly: true })
  });
});