define('checkin/utils/find-and-set-by', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (collectionKey, property, as) {
    return computed(collectionKey, as, {
      get: function get() {
        return _get(this, collectionKey).findBy(property, _get(this, as));
      },
      set: function set(key, value) {
        _set(this, as, value && _get(value, property));
        return value;
      }
    });
  };

  var computed = Ember.computed,
      _get = Ember.get,
      _set = Ember.set;
});