define('checkin/helpers/timer-duration', ['exports', 'dayjs'], function (exports, _dayjs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Helper = Ember.Helper;
  exports.default = Helper.helper(function (params) {
    var from = params[0];
    var to = params[1];

    return _dayjs.default.utc(_dayjs.default.duration((0, _dayjs.default)(to).diff(from)).as('milliseconds')).format('HH:mm:ss');
  });
});