define('checkin/components/each-records-with-meta/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      alias = Ember.computed.alias,
      get = Ember.get;
  exports.default = Component.extend({
    recordArray: null,

    records: alias('recordArray'),

    recordsAndMetas: computed('records.[]', 'recordArray', function () {
      var ret = [];
      var recordArray = get(this, 'recordArray');
      var recordMetas = get(recordArray, 'meta._recordMeta');

      get(this, 'records').forEach(function (record) {
        var foundMeta = recordMetas.find(function (meta) {
          return meta.type == get(record, 'constructor.modelName') && meta.id == get(record, 'id');
        });
        var meta = foundMeta.meta || {};

        ret.push({ record: record, meta: meta });
      });

      return ret;
    })
  });
});