define('checkin/helpers/includes', ['exports', 'ember-composable-helpers/helpers/includes'], function (exports, _includes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _includes.default;
    }
  });
  Object.defineProperty(exports, 'includes', {
    enumerable: true,
    get: function () {
      return _includes.includes;
    }
  });
});