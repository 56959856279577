define('checkin/components/core-peek/content-by-category/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      get = Ember.get,
      getOwner = Ember.getOwner;
  exports.default = Component.extend({
    tagName: '',

    componentName: computed(function () {
      var name = ["core-peek/content-by-category", get(this, 'core.category.name'), 'main'].join("/");

      var owner = getOwner(this);
      var lookup = owner.lookup('component-lookup:main');

      if (lookup.container.factoryFor('component:' + name)) {
        return name;
      } else {
        return "core-peek/content-default/main";
      }
    })
  });
});