define('checkin/services/shelves', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service,
      inject = Ember.inject,
      computed = Ember.computed,
      get = Ember.get;
  exports.default = Service.extend({
    store: inject.service(),

    // all: computed(function() {
    //   return get(this, 'store').query('shelf', { page: { size: 10000 } });
    // }),

    nums: computed(function () {
      return get(this, 'store').findAll('shelf-num').then(function (results) {
        return get(results, 'firstObject.list');
      });
    }),

    registerNum: function registerNum(num) {
      get(this, 'nums').then(function (nums) {
        nums.addObject(num);
      });
    },


    actions: {
      search: function search(keywords) {
        return get(this, 'store').query('shelf', { page: { size: 100 }, filter: { keywords: keywords } }).then(function (shelves) {
          return shelves.mapBy('num');
        });
      }
    }
  });
});