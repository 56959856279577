define('checkin/models/shelf-report-line', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    shelf: belongsTo('shelf'),
    category: belongsTo('category')
  });
});