define('checkin/components/core-label/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      get = Ember.get,
      keys = Ember.keys;
  exports.default = Component.extend({
    classNames: ['ui', 'label'],
    classNameBindings: ['core.interchanges.length:brown:black'],

    core: null,

    coreFirstKeyedInterchangeKey: computed('core.keyedInterchanges', function () {
      return get(keys(get(this, 'core.keyedInterchanges')), 'firstObject');
    })
  });
});