define('checkin/transforms/map', ['exports', 'ember-data', 'npm:humps'], function (exports, _emberData, _npmHumps) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Transform = _emberData.default.Transform;
  var $ = Ember.$;
  exports.default = Transform.extend({
    serialize: function serialize(value, options) {
      if (!$.isPlainObject(value)) {
        return {};
      } else {
        return options.transform ? _npmHumps.default.depascalizeKeys(value) : value;
      }
    },

    deserialize: function deserialize(value, options) {
      if (!$.isPlainObject(value)) {
        return {};
      } else {
        return options.transform ? _npmHumps.default.pascalizeKeys(value) : value;
      }
    }
  });
});