define('checkin/serializers/application', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var JSONAPISerializer = _emberData.default.JSONAPISerializer;
  exports.default = JSONAPISerializer.extend({
    serializeAttribute: function serializeAttribute(snapshot, json, key, attribute) {
      if (attribute.options && attribute.options.readOnly) {
        return;
      }

      this._super.apply(this, arguments);
    },
    _normalizeResponse: function _normalizeResponse(store, primaryModelClass, payload, id, requestType, isSingle) {
      var documentHash = this._super.apply(this, arguments);

      if (!documentHash.meta) {
        documentHash.meta = {};
      }

      documentHash.meta._recordMeta = [];

      if (!isSingle) {
        documentHash.data.forEach(function (data) {
          if (data.meta) {
            documentHash.meta._recordMeta.push({
              id: data.id,
              type: data.type,
              meta: data.meta
            });
          }
        });
      }

      return documentHash;
    },
    normalize: function normalize(modelClass, resourceHash) {
      var normalized = this._super.apply(this, arguments);
      normalized.data.meta = resourceHash.meta || {};

      return normalized;
    },
    normalizeQueryResponse: function normalizeQueryResponse(store, clazz, payload) {
      var result = this._super.apply(this, arguments);
      result.meta = result.meta || {};

      if (payload.links) {
        result.meta.links = payload.links;
      }

      return result;
    }
  });
});