define('checkin/components/checked-dropdown/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set,
      bool = Ember.computed.bool;
  exports.default = Component.extend({
    options: null,
    selected: null,
    placeholder: null,

    hasSelection: bool('selected'),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this.$('.dropdown').dropdown({
        action: function action(text, value) {
          _this.$('.dropdown').dropdown('set selected', value).dropdown('hide');
          set(_this, 'selected', get(_this, 'options').findBy('id', value));
        }
      });
    },


    actions: {
      clear: function clear() {
        this.$('.dropdown').dropdown('clear').dropdown('hide');
        set(this, 'selected', null);
      }
    }
  });
});