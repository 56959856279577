define('checkin/components/action-dropdown/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set,
      bool = Ember.computed.bool;
  exports.default = Component.extend({
    classNames: 'ui floating dropdown icon button'.w(),

    options: null,
    selected: null,
    placeholder: null,

    hasSelection: bool('selected'),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this.$().dropdown({
        allowTab: false,
        action: function action(text, value) {
          _this.$().dropdown('set selected', value);
          set(_this, 'selected', get(_this, 'options').findBy('id', value));
        },

        onHide: function onHide() {
          _this.$().dropdown('clear');
          set(_this, 'selected', null);
        }
      });
    },


    actions: {
      clear: function clear() {
        this.$().dropdown('clear');
      },
      hide: function hide() {
        this.$().dropdown('hide');
      }
    }
  });
});