define('checkin/routes/orders/order/lines/line', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('order-line', params.line_id, { include: 'order,core.category,variant' });
    }
  });
});