define('checkin/components/navigable-list/item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      get = Ember.get;
  exports.default = Component.extend({
    // attributeBindings: ['tabindex'],
    // tabindex: computed('isSelected', function() {
    //   return get(this, 'isSelected') ? 0 : -1;
    // }),

    index: null,
    selectedIndex: null,

    isSelected: computed('index', 'selectedIndex', function () {
      return get(this, 'index') === get(this, 'selectedIndex');
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('index', this.register(this));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.unregister(this);
    },
    click: function click() {
      this.selectIndex(get(this, 'index'));
    }
  });
});