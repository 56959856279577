define('checkin/models/order', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    vendor: belongsTo('vendor'),
    variantName: attr('string'),

    pullers: hasMany('user'),

    code: attr('string'),
    num: attr('string'),

    currency: attr('string', { defaultValue: 'USD' }),
    hasGst: attr('boolean', { defaultValue: false }),

    shippingAmount: attr('number'),
    taxesAmount: attr('number', { readOnly: true }),

    subtotal: attr('number', { readOnly: true }),
    total: attr('number', { readOnly: true }),

    shippedOn: attr('date'),

    collected: attr('boolean'),
    pulled: attr('boolean'),

    linesCount: attr('number', { readOnly: true }),
    pulledLinesCount: attr('number', { readOnly: true }),
    flaggedLinesCount: attr('number', { readOnly: true }),

    pullable: attr('boolean', { readOnly: true }),

    createdAt: attr('date')
  });
});