define('checkin/components/allocation-editor/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      service = Ember.inject.service;
  exports.default = Component.extend({
    shelves: service(),

    changeset: null,

    allocation: null,
    snapshot: null
  });
});