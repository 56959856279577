define('checkin/helpers/conditional-confirm', ['exports', 'cac-components/helpers/conditional-confirm'], function (exports, _conditionalConfirm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _conditionalConfirm.default;
    }
  });
});