define('checkin/adapters/shelf-num', ['exports', 'ember-data', 'cac-common/mixins/data-adapter-mixin'], function (exports, _emberData, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getOwner = Ember.getOwner,
      computed = Ember.computed,
      get = Ember.get;
  exports.default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    buildURL: function buildURL() {
      var ENV = getOwner(this).resolveRegistration('config:environment');
      var config = ENV.APP;
      var host = config.API_HOST;
      var namespace = config.DATA_NAMESPACE;

      return [host, namespace, 'shelf_nums'].join('/');
    }
  });
});