define('checkin/services/undo', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service,
      inject = Ember.inject,
      get = Ember.get,
      set = Ember.set;
  exports.default = Service.extend({
    notify: inject.service(),

    register: function register(fn, message) {
      get(this, 'notify').success({ text: message, closeAfter: 12500, canUndo: true, undo: function undo(message) {
          fn().then(function () {
            set(message, 'canUndo', false);
          });
        } });
    }
  });
});