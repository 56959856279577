define('checkin/models/employee', ['exports', 'checkin/models/profile', 'ember-data'], function (exports, _profile, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = _profile.default.extend({
    name: attr('string'),
    canAdmin: attr('boolean'),

    user: belongsTo('user'),
    refable: attr('boolean'),
    chattable: attr('boolean'),

    createdAt: attr('date')
  });
});