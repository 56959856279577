define('checkin/components/user-profile/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      inject = Ember.inject,
      alias = Ember.computed.alias;
  exports.default = Component.extend({
    classNames: ['user-profile'],

    session: inject.service(),
    username: alias('session.data.authenticated.username'),

    didInsertElement: function didInsertElement() {
      this.$('.dropdown').dropdown({
        on: 'hover',
        action: 'nothing'
      });
    }
  });
});