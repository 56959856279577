define('checkin/components/input-debouncer/debounceable-input/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var TextField = Ember.TextField,
      get = Ember.get,
      set = Ember.set;
  exports.default = TextField.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.$() || !this.$().is(':focus')) {
        set(this, 'value', get(this, 'rawValue'));
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      if (!this.$() || !this.$().is(':focus')) {
        set(this, 'value', get(this, 'rawValue'));
      }
    }
  });
});