define('checkin/helpers/entries', ['exports', 'ember-composable-helpers/helpers/entries'], function (exports, _entries) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _entries.default;
    }
  });
  Object.defineProperty(exports, 'entries', {
    enumerable: true,
    get: function () {
      return _entries.entries;
    }
  });
});