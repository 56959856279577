define('checkin/helpers/pluralize-word', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Helper = Ember.Helper,
      Inflector = Ember.Inflector;
  exports.default = Helper.helper(function (params) {
    var inflector = new Inflector(Inflector.defaultRules);
    return params[1] === 1 ? inflector.singularize(params[0]) : inflector.pluralize(params[0]);
  });
});