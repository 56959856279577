define('checkin/routes/orders/order/lines/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var order = this.modelFor('orders.order');

      var include = 'order,core,variant';
      var filter = { order_id: Ember.get(order, 'id') };
      var sort = 'flagged_at,-pulled_at';

      return this.store.query('order-line', { include: include, filter: filter, sort: sort });
    }
  });
});