define('checkin/components/multiple-select/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      run = Ember.run,
      get = Ember.get,
      set = Ember.set;
  exports.default = Component.extend({
    classNames: 'ui fluid multiple search selection dropdown'.w(),
    classNameBindings: ['isEnabled::disabled', 'isEnabled::loading'],

    options: null,
    selected: null,
    maxSelections: null,

    isEnabled: false,

    placeholder: 'Select',

    didInsertElement: function didInsertElement() {
      var _this = this;

      this.$().dropdown({
        maxSelections: get(this, 'maxSelections') || false,
        onChange: function onChange(value) {
          run(function () {
            set(_this, 'selected', value.split(','));
          });
        }
      }).on('keypress', function (e) {
        if (e.keyCode === 13) {
          get(_this, 'commit')();
        }
      });
    },
    didRender: function didRender() {
      var _this2 = this;

      set(this, 'isEnabled', true);

      run.next(function () {
        var $el = _this2.$();
        if ($el) {
          $el.dropdown('set selected', get(_this2, 'selected'));
        }
      });
    }
  });
});