define('checkin/models/checkin-report', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    count: attr('number'),
    scrapCount: attr('number'),
    exportCount: attr('number'),
    checkins: hasMany('checkin'),

    createdAt: attr('date')
  });
});