define('checkin/components/batch-selector/component', ['exports', 'checkin/validations/batch'], function (exports, _batch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({ BatchValidations: _batch.default }, {
    tagName: '',

    batches: null,
    customers: null,

    select: null,
    create: null
  });
});