define('checkin/routes/application', ['exports', 'cac-common/mixins/application-route-mixin'], function (exports, _applicationRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route,
      inject = Ember.inject,
      get = Ember.get;
  exports.default = Route.extend(_applicationRouteMixin.default, {
    session: inject.service(),

    actions: {
      confirm: function confirm() {
        var msg = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "Are you sure?";

        if (window.confirm(msg)) {
          return Ember.RSVP.resolve();
        } else {
          return Ember.RSVP.reject();
        }
      },
      invalidateSession: function invalidateSession() {
        return get(this, 'session').invalidate();
      }
    }
  });
});