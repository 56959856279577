define('checkin/components/core-search/filters/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      get = Ember.get;
  exports.default = Component.extend({
    availableCategories: computed('categories', 'unfilteredCategoryIds.[]', function () {
      var ids = get(this, 'unfilteredCategoryIds') || [];

      return get(this, 'categories').filter(function (category) {
        return ids.includes(+get(category, 'id'));
      });
    })
  });
});