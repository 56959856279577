define('checkin/components/photo-swipe/component', ['exports', 'ember-photoswipe/components/photo-swipe'], function (exports, _photoSwipe) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _photoSwipe.default.extend({
    history: false,

    clickToCloseNonZoomable: false,
    closeOnScroll: false,

    shareButtons: Ember.computed(function () {
      return [{ id: 'download', label: 'Download image', url: '{{raw_image_url}}', download: true }];
    }),

    openOnItems: true,
    index: 0,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super();

      if (Ember.get(this, 'openOnItems') && Ember.get(this, 'items.length')) {
        this.open({
          index: Ember.get(this, 'index')
        });
      }
    }
  });
});