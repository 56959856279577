define('checkin/adapters/application', ['exports', 'checkin/config/environment', 'ember-data', 'cac-common/mixins/data-adapter-mixin'], function (exports, _environment, _emberData, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      service = Ember.inject.service,
      computed = Ember.computed;
  exports.default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    host: _environment.default.APP.API_HOST,
    namespace: _environment.default.APP.API_NAMESPACE,

    pusher: service(),

    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      if (query && query.link) {
        var link = query.link;
        delete query.link;

        if (link.charAt(0) === '/') {
          return [get(this, 'host'), link].join('');
        }

        return link;
      } else {
        return this._super.apply(this, arguments);
      }
    },


    headers: computed('pusher.socketId', function () {
      if (get(this, 'pusher.socketId')) {
        return {
          'X-Pusher-Socket-Id': get(this, 'pusher.socketId')
        };
      } else {
        return {};
      }
    })
  });
});