define('checkin/components/comment-editor/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set;
  exports.default = Component.extend({
    classNames: ['comment-editor'],
    comment: null,

    click: function click() {
      var answer = window.prompt("Comments for this core", get(this, 'comment') || '');

      if (!(get(this, 'comment') && answer === null)) {
        set(this, 'comment', answer);
      }
    }
  });
});