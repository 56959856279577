define('checkin/models/cart-line', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get;
  var Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    cart: belongsTo('cart'),
    contents: attr('map'),

    imageUrls: attr('list', {
      defaultValue: function defaultValue() {
        return [];
      },
      readOnly: true }),
    imageKeys: attr('list', {
      defaultValue: function defaultValue() {
        return [];
      },
      writeOnly: true }),

    total: attr('number', { readOnly: true }),
    exchangedTotal: attr('number', { readOnly: true }),
    appliedExchangeRate: attr('number', { readOnly: true }),

    // so we don't have to re-load all cart-lines when cart Xrate is changed
    locallyComputedExchangedTotal: computed('cart.exchangeRate', 'total', function () {
      return get(this, 'cart.exchangeRate') * get(this, 'total');
    })
  });
});