define('checkin/components/allocation-editor/with-new-shelf/component', ['exports', 'checkin/validations/shelf'], function (exports, _shelf) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      inject = Ember.inject,
      get = Ember.get,
      set = Ember.set;
  exports.default = Component.extend(_shelf.default, {
    store: inject.service(),

    shelf: null,
    allocation: null,
    categories: null,

    didReceiveAttrs: function didReceiveAttrs() {
      set(this, 'shelf', get(this, 'store').createRecord('shelf', { categories: [get(this, 'category')] }));
    },


    actions: {
      useShelfForAllocation: function useShelfForAllocation(allocation, shelf) {
        set(allocation, 'shelfNums', [get(shelf, 'num')]);
      }
    }
  });
});