define('checkin/services/fooshelves', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service,
      inject = Ember.inject,
      computed = Ember.computed,
      get = Ember.get;
  exports.default = Service.extend({
    store: inject.service(),

    all: computed(function () {
      var _this = this;

      return get(this, 'store').query('shelf', { page: { size: 10000 } }).then(function () {
        return get(_this, 'store').peekAll('shelf');
      });
    })
  });
});