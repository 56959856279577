define('checkin/models/order-line', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    order: belongsTo('order'),
    num: attr('string'),

    puller: belongsTo('user'),
    boxCode: attr('string'),

    core: belongsTo('core'),
    variant: belongsTo('variant'),

    description: attr('string', { readOnly: true }),
    interchanges: attr('list', { readOnly: true }),

    shelfNums: attr('list'),

    quantityAvailableOverride: attr('number', { writeOnly: true }),
    quantityAvailable: attr('number'),
    quantityRequested: attr('number', { readOnly: true }),
    quantityPulled: attr('number', { defaultValue: 0 }),

    variantQuantityAvailableOverride: attr('number', { writeOnly: true }),
    variantQuantityAvailable: attr('number'),
    variantQuantityRequested: attr('number', { readOnly: true }),
    variantQuantityPulled: attr('number', { defaultValue: 0 }),

    quantityOverride: attr('number'),
    variantQuantityOverride: attr('number'),

    unitPrice: attr('number', { readOnly: true }),
    total: attr('number', { readOnly: true }),

    comments: attr('string'),

    pulled: attr('boolean'),

    flagged: attr('boolean'),
    flaggedReason: attr('string')
  });
});