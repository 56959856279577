define('checkin/helpers/now', ['exports', 'dayjs'], function (exports, _dayjs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Helper = Ember.Helper,
      run = Ember.run;
  exports.default = Helper.extend({
    compute: function compute(params, _ref) {
      var _this = this;

      var interval = _ref.interval;

      if (interval) {
        this.intervalTimer = setTimeout(function () {
          run(function () {
            return _this.recompute();
          });
        }, parseInt(interval, 10));
      }

      return (0, _dayjs.default)();
    },
    clearTimer: function clearTimer() {
      clearTimeout(this.intervalTimer);
    },
    destroy: function destroy() {
      this.clearTimer();
      this._super.apply(this, arguments);
    }
  });
});