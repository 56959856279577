define('checkin/routes/orders/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: {
      active: {
        refreshModel: true
      }
    },

    infinity: Ember.inject.service(),

    model: function model(params) {
      return Ember.get(this, 'infinity').model('order', { include: 'vendor', filter: { collected: true, pulled: !params.active } });
    }
  });
});