define('checkin/components/stockpiles-editor/stockpile-editor/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      get = Ember.get,
      set = Ember.set;
  exports.default = Component.extend({
    core: null,
    variant: null,

    count: null,

    stockpile: computed('core.stockpiles.@each.variant', 'variant', function () {
      var variant = get(this, 'variant');

      return get(this, 'core.stockpiles').find(function (stockpile) {
        return get(stockpile, 'variant.content') === variant;
      });
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      set(this, 'count', get(this, 'stockpile.count'));
    }
  });
});