define('checkin/components/selectable-list/component', ['exports', 'ember-keyboard'], function (exports, _emberKeyboard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      on = Ember.on,
      get = Ember.get,
      set = Ember.set;
  exports.default = Component.extend(_emberKeyboard.EKMixin, {
    keyboardActivated: false,

    items: null,
    selectedIndex: -1,

    didReceiveAttrs: function didReceiveAttrs() {
      set(this, 'selectedIndex', get(this, 'items').indexOf(get(this, 'selectedItem')));
    },


    onArrow: on((0, _emberKeyboard.keyDown)('ArrowLeft'), (0, _emberKeyboard.keyDown)('ArrowRight'), function (event) {
      var code = (0, _emberKeyboard.getCode)(event);
      var index;

      switch (code) {
        case 'ArrowLeft':
          index = Math.max(-1, get(this, 'selectedIndex') - 1);
          break;

        case 'ArrowRight':
          index = Math.min(get(this, 'items.length') - 1, get(this, 'selectedIndex') + 1);
          break;
      }

      set(this, 'selectedIndex', index);
      this['on-key'](get(this, 'items').objectAt(get(this, 'selectedIndex')) || null);
    }),

    selectIndex: function selectIndex(index) {
      set(this, 'selectedIndex', index);
      this['on-click'](get(this, 'items').objectAt(get(this, 'selectedIndex')) || null);
    },


    actions: {
      selectIndex: function selectIndex(index) {
        this.selectIndex(index);
      }
    }
  });
});