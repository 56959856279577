define('checkin/services/pager', ['exports', 'checkin/utils/resolve-as'], function (exports, _resolveAs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ArrayProxy = Ember.ArrayProxy,
      A = Ember.A,
      RSVP = Ember.RSVP,
      get = Ember.get,
      set = Ember.set,
      _Ember$computed = Ember.computed,
      filterBy = _Ember$computed.filterBy,
      alias = _Ember$computed.alias,
      bool = _Ember$computed.bool;
  exports.default = ArrayProxy.extend({
    store: null,

    content: null,
    records: null,
    firstPage: null,

    init: function init() {
      set(this, 'content', A());
      set(this, 'records', A());

      this.addFirstPage(get(this, 'firstPage'));
    },

    resolvePages: function resolvePages() {
      return (0, _resolveAs.default)(RSVP.all(this.toArray()), this);
    },
    addFirstPage: function addFirstPage(page) {
      return this._addPage(page);
    },
    addLinkedPage: function addLinkedPage(page) {
      var _this = this;

      return this._addPage(page).catch(function (reason) {
        get(_this, 'content').removeObject(page);
        set(_this, 'content', get(_this, 'content').toArray());

        RSVP.rethrow(reason);
      });
    },
    _addPage: function _addPage(page) {
      var _this2 = this;

      get(this, 'content').addObject(page);
      set(this, 'content', get(this, 'content').toArray());

      return page.then(function (records) {
        get(_this2, 'records').addObjects(records.toArray());
        return records;
      });
    },


    fulfilledPages: filterBy('[]', 'isFulfilled', true),
    lastFulfilledPage: alias('fulfilledPages.lastObject'),

    canFetchNext: bool('lastFulfilledPage.content.meta.links.next'),

    actions: {
      fetchNext: function fetchNext() {
        var _this3 = this;

        if (!get(this, 'canFetchNext')) {
          return RSVP.reject();
        }

        var store = get(this, 'store');
        var promise;

        var lastFulfilledPage = get(this, 'lastFulfilledPage');
        var lastPage = get(this, 'lastObject');

        // Only do request if we're not already waiting on fetching (i.e., the next page)
        if (lastFulfilledPage === lastPage) {
          promise = lastPage.then(function (page) {
            return _this3.addLinkedPage(store.query(page.modelName, { link: get(page, 'meta.links.next') }));
          });
        } else {
          promise = lastPage;
        }

        return promise.then(function (records) {
          return records.toArray();
        });
      }
    }
  }).reopenClass({
    isServiceFactory: true
  });
});