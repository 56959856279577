define('checkin/components/checkins-editor/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      service = Ember.inject.service,
      computed = Ember.computed,
      get = Ember.get;
  exports.default = Component.extend({
    classNames: ['checkins-editor'],

    store: service(),
    checkins: computed(function () {
      return get(this, 'store').query('checkin', {
        include: 'batch.customer',
        sort: '-created_at',
        page: { size: 100 }
      });
    })
  });
});