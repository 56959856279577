define('checkin/models/allocation', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.schema = undefined;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  var keys = Object.keys;
  var schema = exports.schema = {
    core: belongsTo('core'),
    shelfNums: attr('list', {
      defaultValue: function defaultValue() {
        return [];
      }
    })
  };

  exports.default = Model.extend(schema, { keys: keys(schema) });
});