define('checkin/models/cat', ['exports', 'ember-data', 'checkin/models/imageable'], function (exports, _emberData, _imageable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr;
  exports.default = _imageable.default.extend({
    title: attr('string'),

    price: attr('number'),

    priceTrend: attr('number'),

    imageUrls: attr('list', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),

    hint: attr('string'),
    make: belongsTo('make')
  });
});