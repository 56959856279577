define('checkin/mixins/saving', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    validateAndSave: function validateAndSave(changeset, record) {
      var _this = this;

      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      return changeset.validate().then(function () {
        if (Ember.get(changeset, 'isValid')) {
          if (options.beforeSave) {
            options.beforeSave.apply(_this, [record]);
          }

          return changeset.save().catch(function (reason) {
            Ember.get(record, 'errors').forEach(function (_ref) {
              var attribute = _ref.attribute,
                  message = _ref.message;

              changeset.addError(attribute, message);
            });

            return Ember.RSVP.reject(reason);
          });
        } else {
          return Ember.RSVP.reject();
        }
      });
    }
  });
});