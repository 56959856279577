define('checkin/serializers/order', ['exports', 'checkin/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      pullers: { serialize: true }
    }
  });
});