define('checkin/components/navigable-list/component', ['exports', 'ember-keyboard'], function (exports, _emberKeyboard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      $ = Ember.$,
      A = Ember.A,
      run = Ember.run,
      on = Ember.on,
      get = Ember.get,
      set = Ember.set;
  exports.default = Component.extend(_emberKeyboard.EKMixin, {
    keyboardActivated: false,

    scrolling: false,
    children: null,

    selectedIndex: -1,

    upKey: 'ArrowUp',
    downKey: 'ArrowDown',

    bar: on((0, _emberKeyboard.keyDown)('ArrowUp'), (0, _emberKeyboard.keyDown)('ArrowDown'), function (event) {
      var _this = this;

      event.preventDefault();

      var up = (0, _emberKeyboard.getCode)(event) === 'ArrowUp';
      var next = this.get('children').mapBy('element').objectAt(this.get('selectedIndex') + (up ? -1 : 1));

      if (next) {
        if (this.elIsVisible(next)) {
          this.set('selectedIndex', this.get('selectedIndex') + (up ? -1 : 1));
        } else {
          var otherNext = this.get('children').mapBy('element').findIndex(function (el) {
            return _this.elIsVisible(el);
          });

          this.set('selectedIndex', otherNext);
        }
      } else {
        if (this['on-reached-end']) {
          if (up) {
            this.set('selectedIndex', -1);
          } else {
            this['on-reached-end']().then(function () {
              _this.incrementProperty('selectedIndex');
              Ember.run.next(function () {
                _this.foo();
              });
            });
          }
        }
      }
    }),

    elIsVisible: function elIsVisible(el) {
      return el.getBoundingClientRect().top > 0 + 119 && el.getBoundingClientRect().bottom < $(window).height();
    },


    foo: on((0, _emberKeyboard.keyDown)('ArrowUp'), (0, _emberKeyboard.keyDown)('ArrowDown'), function (event) {
      var _this2 = this;

      run(function () {
        var up = event && (0, _emberKeyboard.getCode)(event) === 'ArrowUp';
        var selected = _this2.get('children').objectAt(_this2.get('selectedIndex'));

        if (selected) {
          var $el = selected.$();
          var distance;
          var rect = $el.get(0).getBoundingClientRect();

          if ($el.visible()) {
            if (up) {
              if (rect.top < ($(window).height() - 119) / 2) {
                distance = -119;
              }
            } else {
              if (rect.top > ($(window).height() - 119) / 2) {
                distance = 119;
              }
            }
          }

          if (distance) {
            window.scrollTo(0, $(window).scrollTop() + distance);
          }
        }
      });
    }),

    triggerOnSelect: function triggerOnSelect() {
      if (this['on-select']) {
        this['on-select'](get(this, 'children').objectAt(get(this, 'selectedIndex')).get('item'));
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      set(this, 'children', A());
    },


    actions: {
      register: function register(child) {
        get(this, 'children').addObject(child);
        return get(this, 'children.length') - 1;
      },
      unregister: function unregister(child) {
        get(this, 'children').removeObject(child);
      },
      selectIndex: function selectIndex(index) {
        set(this, 'selectedIndex', typeof index !== 'undefined' ? index : -1);
        this.triggerOnSelect();
      }
    }
  });
});