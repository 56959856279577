define('checkin/utils/store-locally', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (depdendentKey) {
    var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

    var storeKey = depdendentKey;

    if (typeof defaultValue != 'undefined' && !store.has(storeKey)) {
      store.set(storeKey, defaultValue);
    }

    return computed(depdendentKey, {
      get: function get() {
        return store.get(storeKey);
      },
      set: function set(key, value) {
        store.set(storeKey, value);
        return value;
      }
    });
  };

  var computed = Ember.computed;
  var _window = window,
      store = _window.store;
});