define('checkin/models/attachment', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    core: belongsTo('core'),

    imageURL: attr(),
    thumbnailURL: attr(),
    caption: attr('string')
  });
});