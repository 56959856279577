define('checkin/models/audit', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    core: belongsTo('core'),
    variant: belongsTo('variant'),

    affectedStockpile: belongsTo('stockpile', { readOnly: true }),
    affectedAllocation: belongsTo('allocation', { readOnly: true }),

    count: attr('number'),
    previousCount: attr('number'),
    createdAt: attr('date', { readOnly: true }),

    user: belongsTo('user')
  });
});