define('checkin/authorizers/application', ['exports', 'cac-common/authorizers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _application.default;
    }
  });
});