define('checkin/initializers/dayjs', ['exports', 'dayjs', 'dayjs/plugin/relativeTime', 'dayjs/plugin/duration', 'dayjs/plugin/utc'], function (exports, _dayjs, _relativeTime, _duration, _utc) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    _dayjs.default.extend(_relativeTime.default);
    _dayjs.default.extend(_duration.default);
    _dayjs.default.extend(_utc.default);
  }

  exports.default = {
    name: 'dayjs',
    initialize: initialize
  };
});