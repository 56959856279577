define('checkin/routes/orders/order', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('order', params.order_id, { include: 'vendor', reload: true });
    },


    actions: {
      transitionToOrder: function transitionToOrder(order) {
        return this.transitionTo('orders.order.lines', Ember.get(order, 'id'));
      },
      transitionToOrderLine: function transitionToOrderLine(order, line) {
        return this.transitionTo('orders.order.lines.line', Ember.get(order, 'id'), Ember.get(line, 'id'));
      }
    }
  });
});